import React, { Component } from 'react';
import { OrderRequestButton } from './OrderRequestButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { StockCheckButton } from './StockCheckButton';
import { OrderCountButton } from './OrderCountButton';

import { Spinner, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GoodsInPopup from '../GoodsIn/GoodsInPopup';

export class StockCheckOrderButton extends Component {
    constructor(props) {
        super(props);
    };

    render() {

        var unit = ""
        if (this.props.itemListing.measurementUnit === "Metres") {
            unit = "m"
        }
        if (this.props.itemListing.measurementUnit === "Grams") {
            unit = "g"
        }

        return (            
            <ButtonGroup vertical size="sm" style={{width:"145px"}}>
                <StockCheckButton itemListingId={this.props.itemListing.id} cachedStockCheck={this.props.itemListing.cachedStockCheck} unit={unit} />
                <OrderCountButton itemListingId={this.props.itemListing.id} />
                <ButtonGroup size="sm">
                    <GoodsInPopup
                        triggerText="Check-In"
                        buttonVariant="info"
                        itemListingId={this.props.itemListing.id}
                        orderId={null}
                        itemName={this.props.itemListing.name}
                        measurementUnit={this.props.itemListing.measurementUnit}
                        itemDimensions={null}
                        onSubmit={() => { }}
                    />
                    <OrderRequestButton itemListingId={this.props.itemListing.id} triggerText='Order' itemName={this.props.itemListing.name} />
                </ButtonGroup>
            </ButtonGroup>
        );
    }
}
