import React, { Component } from 'react';
import { OrderSummaryListing } from './Listings/OrderSummaryListing';


export class OrderList extends Component {

    constructor(props) {
        super(props);
        this.state = { orderData: {}, loading: true };
    }

    static defaultProps = {
        itemListingId: 0,
    }

    componentDidMount() {
        this.populateOrderList();
    }

    renderOrderList(orderData) {
        return (
            <div id="flex-container">
                {orderData.map(orderData => <OrderSummaryListing orderData={orderData} />)}
            </div>
        );
    }

    render() {

        if (this.state.loading) {
            var contents = <p><em>Loading...</em></p>
        }
        else {
            if (this.state.orderData.length > 0) {
                var contents = this.renderOrderList(this.state.orderData);
            }
            else {
                var contents = <p>No orders found</p>
            }
        }

        return (
            <div style={{ padding: "10px"}} >
                <h3>Placed Orders</h3>                
                {contents}
            </div>
        );
    }

    async populateOrderList() {
        if (this.props.itemListingId == 0) {
            const response = await fetch(`api/ordering/list-open-orders`);
            const data = await response.json();
            this.setState({ orderData: data, loading: false });
        }
        else {
            const response = await fetch(`api/ordering/list-item-orders?itemListingId=${this.props.itemListingId}`);
            const data = await response.json();
            this.setState({ orderData: data, loading: false });
        }
    }
}