import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Home extends Component {
    static displayName = Home.name;

    async syncProjects() {
        const response = await fetch(`/api/projectplace/sync-projects`);
        const text = await response.text();
        alert(text);
    }

    render() {        
        return (
            <div>
                <h2>Digital Manufacturing Inventory System</h2>
                <br />
                <br />
                <p>
                    Tools:<br />
                    <ul>
                        <li><Link to={"/stock-check"}>Location Stock Check</Link></li>
                        <li><Link to={"/item/move"}>Move Items</Link></li>
                        <li><Link to={"/item/locate"}>Get Item Last Location</Link></li>
                        <li><Link onClick={this.syncProjects}>Synchronise ProjectPlace Projects</Link></li>
                        <li><Link to={"/database-view"}>Database Query Viewer</Link></li>
                        
                    </ul>
                </p>
            </div>
        );
    }
}
