import React, { Component } from 'react';
import { Spinner, Button } from 'react-bootstrap';
import StockModal from './LocationStockCheckPopup/StockModal';
import 'bootstrap/dist/css/bootstrap.min.css';

export class StockCheckButton extends Component {
    constructor(props) {
        super(props);

        if (this.props.cachedStockCheck) {
            this.state = {
                stockData: { quantity: this.props.cachedStockCheck },
                loading: false,
                isShown: false
            };
        }
        else {
            //If cachedStockCheck is null
            this.state = {
                stockData: { quantity: 0 },
                loading: true,
                isShown: false
            };
            setTimeout(() => {
                this.checkStock();
            }, 1000)
        }                
    }

    static defaultProps = {
        cachedStockCheck: null
    }

    async checkStock() {
        const response = await fetch(`api/items/stock-check?itemListingID=${this.props.itemListingId}`);
        const data = await response.json();
        this.setState({ stockData: data, loading: false });
    }

    // Popup Handlers
    showModal = () => {
        this.setState({ isShown: true }, () => {
            this.closeButton.focus();
        });
        this.toggleScrollLock();
    };
    closeModal = () => {
        this.setState({ isShown: false });
        this.toggleScrollLock();
    };
    onKeyDown = (event) => {
        if (event.keyCode === 27) {
            this.closeModal();
        }
    };
    onClickOutside = (event) => {
        if (this.modal && this.modal.contains(event.target)) return;
        this.closeModal();
    };

    toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };
    // End Popup Handlers

    render() {

        var buttonVariant = "outline-secondary";
        var buttonText;
        var buttonDisabled;
        var unitText = "";

        if (!(this.props.unit === "Item" || this.props.unit === "Individual"))
        {
            unitText = this.props.unit;
        }


        if (this.state.loading)
        {
            buttonText = <span><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Checking</span>
            buttonDisabled = true;
        }
        else
        {
            if (this.state.stockData.quantity <= 0) {
                buttonText = "❌ No Stock"
                buttonVariant = "danger"
                buttonDisabled = true;
            }
            else
            {
                buttonText = "✔️ " + this.state.stockData.quantity + unitText  +" in stock"
                buttonVariant = "outline-success"
                buttonDisabled = false;
            }
        }

        return (
            <React.Fragment>
                <Button variant={buttonVariant} disabled={buttonDisabled} onClick={this.showModal}>
                    {buttonText}
                </Button>

                {this.state.isShown ? (
                    <StockModal
                        onSubmit={this.onSubmit}
                        modalRef={(n) => (this.modal = n)}
                        buttonRef={(n) => (this.closeButton = n)}
                        closeModal={this.closeModal}
                        onKeyDown={this.onKeyDown}
                        onClickOutside={this.onClickOutside}
                        itemListingId={this.props.itemListingId}
                    />
                ) : null}
            </React.Fragment>
        );
    }
}