import React, { Component, Button } from 'react';
import DropdownTreeSelectContainer from './DropDownContainer'
import 'react-dropdown-tree-select/dist/styles.css'


export class UserSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryData: {
                label: 'loading...',
                value: 'loading...',
                isDefaultValue: true,
                disabled: true,
                children: []
            }
        };
    }

    static defaultProps = {
        onSelection: (id) => { }
    }

    componentDidMount() {
        this.populateCategoryList();
    }

    async populateCategoryList() {
        const response = await fetch(`api/users/list-tree`);
        const data = await response.json();
        data.map(user => { return user.isDefaultValue === true ? this.props.onSelection(user.value) : null });
        this.setState({ categoryData: data});
    }

    onChange = (currentNode, selectedNodes) => {        
        if (currentNode.length > 0) {
            this.props.onSelection(currentNode[0].value);
        }

        if (selectedNodes.length > 0) {
            this.props.onSelection(selectedNodes[0].value);
        }
    }

    render() {
        const onSubmit = () => {
            this.populateCategoryList();
        };

        return (
            <div>
                <DropdownTreeSelectContainer data={this.state.categoryData} onChange={this.onChange} mode={'radioSelect'} texts={{ placeholder: 'Select User...' }} />
            </div>
        );
    }
}
