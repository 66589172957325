import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { format } from "date-fns";

export class OrderRequestSummaryListing extends Component {
    constructor(props) {
        super(props);
    };

    render() {

        var updatedDate = new Date(this.props.orderRequestData.lastUpdated);
        return (
            <div style={{ position: "relative", border: "2px solid lightgrey", margin: "5px", padding: "5px", background: "#f4f4f4"}}>
                <p className="item-large-description">
                    Quantity: <b>{this.props.orderRequestData.quantityRemaining}</b>
                    <span style={{ float: "right", textAlign: "right" }}>
                        Requested by: {this.props.orderRequestData.createdBy.name}
                    </span></p>
                <p className="item-large-description">Urgency: <b>{this.props.orderRequestData.urgency}</b> | Reason: <b>{this.props.orderRequestData.comment}</b></p>
                <p className="item-large-description">Updated: <b>{format(updatedDate, "dd MMM yyyy HH:mm")}</b> | State: <b>{this.props.orderRequestData.procurementState}</b></p>
            </div>
        );
    }
}
