import React, { Component } from 'react';
import { DropdownButton, Dropdown, ToggleButton, ToggleButtonGroup, Table } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { OrderRequestListing } from './Listings/OrderRequestListing';
import OrderItemPopup from './OrderItemPopup/OrderItemPopup';
import 'bootstrap/dist/css/bootstrap.min.css';


export class OrderRequestsList extends Component {

    constructor(props) {
        super(props);
        this.state = { orderRequestData: [], groupedOrderData: [], groupedSupplierList: [], loading: true, showOrderPopup: false, showGroupedView: false, stateFilter: "New", supplierFilterId:"1" };
        this.populateOrderRequestList = this.populateOrderRequestList.bind(this);
        this.deleteEntry = this.deleteEntry.bind(this);
        this.updateEntryState = this.updateEntryState.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.updateshowGroupedView = this.updateshowGroupedView.bind(this);
    }

    componentDidMount() {
        this.populateOrderRequestList();
    }

    deleteEntry(orderRequestId) {
        var index = this.state.orderRequestData.findIndex(x => x.id === orderRequestId);

        let items = [...this.state.orderRequestData];
        items.splice(index, 1);
        this.setState({ orderRequestData: items });
    }

    updateEntryState(orderRequestId, newState) {
        var index = this.state.orderRequestData.findIndex(x => x.id === orderRequestId);
        let items = [...this.state.orderRequestData];
        let item = { ...items[index] };
        item.procurementState = newState;
        items[index] = item;
        this.setState({ orderRequestData: items });
    }


    renderOrderRequestList(orderRequestData) {
        return (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {
                    orderRequestData
                            .filter(orderRequestData => orderRequestData.procurementState === this.state.stateFilter)
                            .map(orderRequestData => <OrderRequestListing orderRequestData={orderRequestData} updateList={this.populateOrderRequestList} onDelete={this.deleteEntry} onUpdateState={this.updateEntryState} />)
                }
            </div>
        );
    }

    updateFilter(state) {
        this.setState({ stateFilter: state })
    }

    updateSupplierFilter(id) {
        this.setState({ supplierFilterId: id })
    }

    updateshowGroupedView(show) {
        if (show && !this.state.showGroupedView) {
            this.populateGroupedByOrderRequestList();
        }
        this.setState({ showGroupedView: show });        
    }

    render() {

        var groupedView = null;

        if (this.state.showGroupedView && this.state.loading === false && this.state.supplierFilterId > 0) {
            var groupedSupplierList = Object.values(this.state.groupedSupplierList);
            var groupedOrderData = Object.values(this.state.groupedOrderData);
            
            var selectedSupplierName = groupedSupplierList.filter(supplier => supplier.id === this.state.supplierFilterId)[0].name;

            var csv = "Quantity, Supplier Number, Model Name, Manuf. Number\n"
            {
                groupedOrderData
                    .filter(orderData => orderData.orderDetails.supplierId === this.state.supplierFilterId)
                .map(orderData => csv += `${orderData.totalQuantity }, ${orderData.orderDetails.supplierModelNumber}, ${orderData.orderDetails.modelName}, ${orderData.orderDetails.manufacturerModelNumber}`)
            }

            groupedView =
                <div style={{ marginTop: "10px" }}>
                    <DropdownButton id="dropdown-basic-button" variant="outline-secondary" title={`Showing supplier: ${selectedSupplierName} `}>
                        {groupedSupplierList.map(supplier => <Dropdown.Item onClick={() => this.updateSupplierFilter(supplier.id)} >{supplier.name}</Dropdown.Item>)}
                    </DropdownButton>
                    <hr />
                    <h3>Table view</h3>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Quantity</th>
                                <th>Supplier Number</th>
                                <th>Model Name</th>
                                <th>Manuf. Number</th>
                                <th>Minimum Order</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupedOrderData
                            .filter(orderData => orderData.orderDetails.supplierId === this.state.supplierFilterId)
                                .map(orderData =>
                                    <tr>
                                        <td>{orderData.totalQuantity}</td>
                                        <td>{orderData.orderDetails.supplierModelNumber}</td>
                                        <td>{orderData.orderDetails.modelName}</td>
                                        <td>{orderData.orderDetails.manufacturerModelNumber}</td>
                                        <td>{orderData.orderDetails.minOrderQuantity}</td>
                                    </tr>)}
                        </tbody>
                    </Table>                

                    <h3>Comma separated</h3>
                    <textarea disabled rows="10" style={{ width: "100%" }} value={ csv }>
                    </textarea>

                    <h3>JSON for Excel</h3>
                    <textarea disabled rows="10" style={{ width: "100%" }} value={
                        JSON.stringify(groupedOrderData.filter(orderData => orderData.orderDetails.supplierId === this.state.supplierFilterId))
                    }>
                    </textarea>
                </div>

        }


        var orderDataArray = Object.values(this.state.orderRequestData);

        if (this.state.loading) {
            var contents = <p><em>Loading...</em></p>
        }
        else {
            if (orderDataArray.length > 0) {
                var contents = this.renderOrderRequestList(orderDataArray);
            }
            else {
                var contents = <p><em>No order requests found</em></p>
            }
        }

        return (
            <div style={{ padding: "10px" }} >
                <h3>Order Requests</h3>
                <div>
                    <ToggleButtonGroup type="radio" name="stateSelectRadio" defaultValue={1}>
                        <ToggleButton id="state-radio-1" variant="outline-secondary" value={1} onClick={() => this.updateFilter("New")}> New Requests ({orderDataArray.filter(orderRequestData => orderRequestData.procurementState === "New").length})</ToggleButton>
                        <ToggleButton id="state-radio-2" variant="outline-secondary" value={2} onClick={() => this.updateFilter("Quotation")}> In Quotation ({orderDataArray.filter(orderRequestData => orderRequestData.procurementState === "Quotation").length})</ToggleButton>
                        <ToggleButton id="state-radio-3" variant="outline-secondary" value={3} onClick={() => this.updateFilter("Awaiting Approval")}> Awaiting Approval ({orderDataArray.filter(orderRequestData => orderRequestData.procurementState === "Awaiting Approval").length})</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div style={{marginTop: "10px"}}>
                    {this.state.stateFilter === "New" ?
                        <ToggleButtonGroup type="radio" name="viewSelectRadio" defaultValue={this.state.showGroupedView}>
                            <ToggleButton id="view-radio-1" variant="outline-secondary" value={false} onClick={() => this.updateshowGroupedView(false)}> Request View </ToggleButton>
                            <ToggleButton id="view-radio-2" variant="outline-secondary" value={true} onClick={() => this.updateshowGroupedView(true)}> Consolidated List View </ToggleButton>
                        </ToggleButtonGroup>
                        :
                        null
                    }
                    </div>

                {this.state.showGroupedView && this.state.stateFilter === "New" ?
                    groupedView
                    :
                    contents
                }
            </div>
        );
        
    }

    async populateOrderRequestList() {
        this.setState({ orderRequestData: [], loading: true });
        const response = await fetch(`api/ordering/list-order-requests`);
        const data = await response.json();
        this.setState({ orderRequestData: data, loading: false });
    }

    async populateGroupedByOrderRequestList() {
        this.setState({ groupedOrderData: [], groupedSupplierList: [], loading: true });
        const response = await fetch(`api/ordering/list-order-requests-grouped-by-orderinfo`);
        const data = await response.json();
        var firstSupplierId = 0
        if (data.uniqueSuppliers.length > 0) {
            firstSupplierId = data.uniqueSuppliers[0].id
        }
        this.setState({ groupedOrderData: data.orderRequests, groupedSupplierList: data.uniqueSuppliers, supplierFilterId: firstSupplierId, loading: false });
    }
}