import React, { Component } from 'react';
import { CategorySelector } from '../CategorySelector';

export class Form  extends Component {    
    constructor(props) {
        super(props);
        this.state = { selectedID: 0, formSubmitted: false, buttonText: "Submit" };
    } 

    static defaultProps = {
        onSelection: () => { }
    }

    onCategorySelection = (id) => {
        this.setState({
            selectedID: id
        });
    }

    onSubmit = (event) => {
        this.sendCreateCategory(event.target.name.value, event.target.description.value, event.target.parent_id.value);
        event.preventDefault(event);
        event.preventDefault();
        event.stopPropagation();
    };

    async sendCreateCategory(name, description, parent_id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Name: name, Description: description, ParentId: parent_id })
        };
        console.log(requestOptions)
        const response = await fetch(`api/category/create`, requestOptions).then((response) => response.json());

        this.props.onSubmit();
    }

    render() {

        const submitButtonClicked = () => {
            
            this.setState({
                buttonText: "Please wait..."
                });
                
        }

        return (
            <form onSubmit={this.onSubmit}>
                <h3><center>Create a new category</center></h3>
                <div className="form-group">
                    <label htmlFor="parent_category">Parent Category</label>
                    <CategorySelector onSelection={this.onCategorySelection} />
                    <input type="hidden" id="parent_id" value={this.state.selectedID} />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input className="form-control" id="name" />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea className="form-control" id="description"/>
                </div>
                <div className="form-group">
                    <button disabled={this.state.formSubmitted} onClick={submitButtonClicked} className="form-control btn btn-primary" type="submit">
                        {this.state.buttonText}
                    </button>
                </div>
            </form>
        );
    }
}