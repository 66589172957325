import React, { Component } from 'react';
import { StockLocationListingMinimal } from './StockLicationListingMinimal';
import { StockLocationListing } from './StockLocationListing'
import 'bootstrap/dist/css/bootstrap.min.css';

export class StockLocations extends Component {

    constructor(props) {
        super(props);
        this.state = { stock: [], loading: true };
    }

    static defaultProps = {
        minimalView: false,
    }

    componentDidMount() {
        this.populateStockLocationList();
    }

    renderStockLocationList(stockData) {
        if (this.props.minimalView) {
            return (
                <div id="flex-container">
                    {stockData.map(stockData => <StockLocationListingMinimal stockData={stockData} />)}
                </div>
            );
        }
        else {
            return (
                <div id="flex-container">
                    {stockData.map(stockData => <StockLocationListing stockData={stockData} />)}
                </div>
            );
        }       
    }


    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderStockLocationList(this.state.stockData);
        if (this.props.minimalView) {
            return (<ul class="list-group" >
                <li class="list-group-item-light d-flex justify-content-between align-items-center"><small><b>This item can be found in the following locations:</b></small></li>
                        {contents}
                    </ul>);
        }
        else {
            return (
                <div style={{ padding: "10px" }} >
                    <h3>Stock Locations:</h3>
                    {contents}
                </div>
            );
        }
    }

    async populateStockLocationList() {
        const response = await fetch(`api/items/location-stock-check?itemListingId=${this.props.itemListingId}`);
        const data = await response.json();
        this.setState({ stockData: data, loading: false });
    }
}