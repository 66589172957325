import React, { Component } from 'react';

export class Form  extends Component {    
    constructor(props) {
        super(props);
        this.state = { formSubmitted: false, buttonText: "Submit" };
    } 

    static defaultProps = {
        onSelection: () => { }
    }

    onSubmit = (event) => {
        event.preventDefault(event);
        this.sendCreateStore(JSON.stringify({
            Name: event.target.name.value,
            Location: event.target.location.value,
        }));

        event.preventDefault();
        event.stopPropagation()
    };

    async sendCreateStore(formData) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: formData
        };
        console.log(requestOptions);
        const response = await fetch(`api/location/create-store`, requestOptions).then((response) => response.json());
        this.props.onSubmit();
    }

    render() {

        const submitButtonClicked = () => {
            
            this.setState({
                buttonText: "Please wait..."
                });
                
        }

        return (
            <form onSubmit={this.onSubmit}>
                <h3><center>Create a new store</center></h3>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input className="form-control" id="name" required />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Location</label>
                    <textarea className="form-control" id="location"/>
                </div>                
                <div className="form-group">
                    <button disabled={this.state.formSubmitted} onClick={submitButtonClicked} className="form-control btn btn-primary" type="submit">
                        {this.state.buttonText}
                    </button>
                </div>
            </form>
        );
    }
}