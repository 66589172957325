import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import { SupplierListing } from './Listings/SupplierListing'

export class OrderingOptions extends Component {

    constructor(props) {
        super(props);
        this.state = { orderDetails: [], loading: true };
    }

    componentDidMount() {
        this.populateOrderList();
    }

    renderOrderDetailsList(orderDetail) {
        return (
            <div id="flex-container">
                {orderDetail.map(orderDetail => <SupplierListing orderDetails={orderDetail} onOrderButtonPress={this.props.onOrderButtonPress}/>)}
            </div>
        );
    }

    render() {
        if (this.state.loading) {
            var contents = <p><em>Loading...</em></p>
        }
        else {
            if (this.state.orderDetails.length > 0) {
                var contents = this.renderOrderDetailsList(this.state.orderDetails);
            }
            else {
                var contents = <p><em>None found</em></p>
            }
        }

        return (
            <div style={{ padding: "10px"
            }} >
                <h3>Ordering Options
                    <span style={{ float: "right" }}>
                        <Link to={`/ordering/create/${this.props.itemListingId}`}><Button variant="secondary">Add Supplier</Button></Link>
                    </span>
                </h3>                
                {contents}
            </div>
        );
    }

    async populateOrderList() {
        const response = await fetch(`api/ordering/list-ordering-details?itemListingId=${this.props.itemListingId}`);
        const data = await response.json();
        this.setState({ orderDetails: data, loading: false});
    }
}