import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export class Form  extends Component {    
    constructor(props) {
        super(props);
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1)
        this.state = { selectedDate: tomorrow, formSubmitted: false, buttonText: "Submit" };
        
    } 

    static defaultProps = {
        onSelection: () => { }
    }

    onDateSelection = (date) => {
        console.log(date);
        this.setState({
            selectedDate: date
        });
    }

    onSubmit = (event) => {
        event.preventDefault(event);
        this.sendCreateLocation(JSON.stringify({
            numberOrdered: event.target.numberOrdered.value,
            itemDimensions: event.target.itemDimensions.value,
            estimatedArrivalDate: moment(event.target.estimatedArrivalDate.value).format("yyyy-MM-DD"),
            purchaseValue: event.target.purchaseValue.value,
            orderInfoId: event.target.orderInfoId.value,
            itemListingId: event.target.itemListingId.value,
            orderRequestId: event.target.orderRequestId.value
        }));

        event.preventDefault();
        event.stopPropagation();
    };

    async sendCreateLocation(formData) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: formData
        };
        console.log(requestOptions);
        const response = await fetch(`api/ordering/request-ordered`, requestOptions).then((response) => response.json());
        this.props.onSubmit();
    }

    render() {
        const submitButtonClicked = () => {
            this.setState({
                buttonText: "Please wait..."
            });
        }

        
        if (this.props.measurementUnit === "Item" || this.props.measurementUnit === "Individual")
        {
            var consumableSize = <input type="hidden" id="itemDimensions" value="1" />
        }
        else
        {
            var consumableSize = <div className="form-group">
                <label htmlFor="name">What is its measurement in {this.props.measurementUnit}?</label>
                <input className="form-control" id="itemDimensions" required defaultValue={this.props.defaultCreationQuantity} />
            </div>
        }

        return (
            <form onSubmit={this.onSubmit}>
                <h3><center>Mark request as ordered</center></h3>
                <label htmlFor="name"><b>Item: {this.props.itemName}</b></label>
                <div className="form-group">
                    <label htmlFor="name">How many did you order?</label>
                    <input className="form-control" id="numberOrdered" required />
                </div>

                {consumableSize}

                <div className="form-group">
                    <label htmlFor="description">Approximate arrival date</label>
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.selectedDate}
                        onChange={(date) => this.onDateSelection(date)}
                    />
                    <input type="hidden" id="estimatedArrivalDate" value={this.state.selectedDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="name">How much did each item cost</label>
                    <input className="form-control" id="purchaseValue" required />
                </div>
                <br />
                <div className="form-group">
                    <input type="hidden" id="orderInfoId" value={this.props.orderInfoId} />
                    <input type="hidden" id="itemListingId" value={this.props.itemListingId} />
                    <input type="hidden" id="orderRequestId" value={this.props.orderRequestId} />

                    <button disabled={this.state.formSubmitted} onClick={submitButtonClicked} className="form-control btn btn-primary" type="submit">
                        {this.state.buttonText}
                    </button>
                </div>
            </form>
        );
    }
}