import { useParams } from 'react-router-dom';
import { OrderDetailsForm } from './OrderDetailsForm';

export function CreateOrderDetailsFormWrapper() {
    const { id } = useParams();

    return (
        <OrderDetailsForm itemListingId={id} />
    );
}

export function EditOrderDetailsFormEditWrapper() {
    const { id } = useParams();

    return (
        <OrderDetailsForm editOrderDetailsId={id} />
    );
}