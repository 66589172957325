import React, { Component } from 'react';
import { Modal } from './Modal';

export class OrderItemPopup extends Component {
    constructor(props) {
        super(props);
        this.toggleScrollLock();
    }

    closeModal = () => {
        this.toggleScrollLock();
        this.props.onCloseModal();
    };
    onKeyDown = (event) => {
        if (event.keyCode === 27) {
            this.closeModal();
        }
    };
    onClickOutside = (event) => {
        //if (this.modal && this.modal.contains(event.target)) return;
        //this.closeModal();
    };

    toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };

    onSubmit = (event) => {        
        this.props.onSubmit();
        this.closeModal();
    };

    render() {
        return (
            <Modal
                onSubmit={this.onSubmit}
                modalRef={(n) => (this.modal = n)}
                buttonRef={(n) => (this.closeButton = n)}
                closeModal={this.closeModal}
                onKeyDown={this.onKeyDown}
                onClickOutside={this.onClickOutside}
                itemName={this.props.itemName}
                orderInfoId={this.props.orderInfoId}
                itemListingId={this.props.itemListingId}
                measurementUnit={this.props.measurementUnit}
                defaultCreationQuantity={this.props.defaultCreationQuantity}
                orderRequestId={this.props.orderRequestId}
            />
        );
    }
}

export default OrderItemPopup;