import React, { Component } from 'react';

export class Form  extends Component {    
    constructor(props) {
        super(props);
        this.state = { selectedID: 0, formSubmitted: false, buttonText: "Submit" };
    } 

    static defaultProps = {
        onSelection: () => { }
    }

    onCategorySelection = (id) => {
        this.setState({
            selectedID: id
        });
    }

    onSubmit = (event) => {
        event.preventDefault(event);
        this.sendCreateCategory(JSON.stringify({
            Name: event.target.name.value
        }));

        event.preventDefault();
        event.stopPropagation()
    };

    async sendCreateCategory(formData) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: formData
        };
        console.log(requestOptions);
        const response = await fetch(`api/items/add-manufacturer`, requestOptions).then((response) => response.json());
        this.props.onSubmit();
    }

    render() {
        const submitButtonClicked = () => {
            this.setState({
                buttonText: "Please wait..."
                });
        }

        return (
            <form onSubmit={this.onSubmit}>
                <h3><center>Add new equipment manufacturer</center></h3>
                <div className="form-group" style={{ paddingBottom: "15px" }}>
                    <label htmlFor="name">Company / Brand name</label>
                    <input className="form-control" id="name" required />
                </div>
                <div className="form-group">
                    <button disabled={this.state.formSubmitted} onClick={submitButtonClicked} className="form-control btn btn-primary" type="submit">
                        {this.state.buttonText}
                    </button>
                </div>
            </form>
        );
    }
}