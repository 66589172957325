import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

export class StockLocationListingMinimal extends Component {
    constructor(props) {
        super(props);
    };

    render() {
        var badgetype = "badge bg-success"
        if (this.props.stockData.quantity <= 0) {
            badgetype = "badge bg-danger"
        }
        return (
            < li class="d-flex justify-content-between align-items-center">
                <small>
                  <b>{this.props.stockData.location.barcode}</b> - {this.props.stockData.location.identifier} [{this.props.stockData.store.name}]
                </small>
                <span class={badgetype}>{this.props.stockData.quantity}</span>
            </li>
        );
    }
}