import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AlternateSignin } from './AlternateSignin';

export class NoAccess extends Component {
    constructor(props) {
        super(props);        
    }

    

    render() {

        if (this.props.userData.showAlternateLogin) {
            return (
                <AlternateSignin/>
            );
        }
        else {
            return (
                <div>
                    {this.props.userData.userLoggedIn ?
                        <h2>You are signed-in but do not have access to this system.</h2>
                        :
                        <h2>Please sign-in to continue.</h2>
                    }
                </div>
            );
        }
    }
}
