import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export class TransactionUndo extends Component {
    state = { isShown: false };
    showModal = () => {
        this.setState({ isShown: true });
        this.toggleScrollLock();
    };

    static defaultProps = {
        onSubmit: (id) => { },
        transactionUUID: null,
        id: null,
        itemName: ""
    }
    
    onRequestUndo() {
        const response = fetch(`api/items/undo-transaction?id=${this.props.id}&transactionId=${this.props.transactionUUID}`).then(this.props.onSubmit(this.props.id));
    }

    undoConfirmationButton = () => {
        confirmAlert({
            title: 'Undo Transaction',
            message: 'Do you want to undo the transaction for item: \"' + this.props.itemName + '\"?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.onRequestUndo(),
                    className: 'btn btn-danger'
                },
                {
                    label: 'No',
                    className: 'btn btn-primary'
                }
            ]
        });
    };

    render() {
        return (
            <Button variant="warning" onClick={this.undoConfirmationButton} type={"button"}>Undo!</Button>
        );
    }
}

export default TransactionUndo;