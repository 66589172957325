import React, { Component, Button } from 'react';
import DropdownTreeSelectContainer from './DropDownContainer'
import 'react-dropdown-tree-select/dist/styles.css'


export class ProjectSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryData: {
                label: 'loading...',
                value: 'loading...',
                isDefaultValue: true,
                disabled: true,
                children: []
            }
        };
    }

    static defaultProps = {
        onSelection: (id) => { }
    }

    componentDidMount() {
        this.populateCategoryList();
    }

    async populateCategoryList() {
        const response = await fetch(`api/projects/list-tree`);
        const data = await response.json();
        data.map(project => { return project.isDefaultValue === true ? this.props.onSelection(project.value) : null });
        this.setState({ categoryData: data});
    }

    onChange = (currentNode, selectedNodes) => {        
        if (selectedNodes.length > 0) {
            this.props.onSelection(selectedNodes[0].value);
        }
        else {
            this.props.onSelection(0);
        }
    }

    render() {
        const onSubmit = () => {
            this.populateCategoryList();
        };

        return (
            <div>
                <DropdownTreeSelectContainer data={this.state.categoryData} onChange={this.onChange} mode={'radioSelect'} texts={{ placeholder: 'Select project...' }} />
            </div>
        );
    }
}
