import React, { Component } from 'react';
import { SupplierPopup } from "../../SupplierPopup/SupplierPopup"
import GoodsInPopup from '../../GoodsIn/GoodsInPopup';

export class OrderSummaryListing extends Component {
    constructor(props) {
        super(props);
        this.state = { orderAmount: this.props.orderData.numberOutstanding };
        this.reduceOutstanding = this.reduceOutstanding.bind(this);
    };

    reduceOutstanding(amount) {
        this.setState({ orderAmount: Number(this.state.orderAmount) - Number(amount) });
    }

    render() {

        if (this.state.orderAmount > 0) {
            return (
                <div style={{ position: "relative", border: "2px solid lightgrey", margin: "5px", padding: "5px", background: "#f4f4f4" }}>
                    <p className="item-large-description">
                        Supplier: <SupplierPopup supplierData={this.props.orderData.orderDetails.supplier} triggerText={this.props.orderData.orderDetails.supplier.name} showLink={true} />
                        
                        <span style={{ float: "right", textAlign: "right" }}>
                            Ordered by: {this.props.orderData.orderedBy.name}<br />
                            <GoodsInPopup triggerText="Receive Goods" buttonVariant="outline-success" itemListingId={this.props.orderData.itemListingId} orderId={this.props.orderData.id} itemName={this.props.orderData.orderDetails.modelName + " / " + this.props.orderData.orderDetails.modelNumber} measurementUnit={this.props.orderData.itemListing.measurementUnit} itemDimensions={this.props.orderData.itemListing.measurementUnit === "Item" ? this.state.orderAmount : this.props.orderData.itemDimensions} onSubmit={this.reduceOutstanding} />
                        </span></p>
                    <p className="item-large-description"><b>Quantity: {this.state.orderAmount}</b> | Estimated Arrival: {this.props.orderData.estimatedArrivalDate}</p>
                    <p className="item-large-description">Part Name/Number: <b>{this.props.orderData.orderDetails.modelName} / {this.props.orderData.orderDetails.supplierModelNumber}</b></p>
                </div>
            );
        }
        else {
            return null;
        }
    }
}
