import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { StockCheckOrderButton } from '../StockOrderButton/StockCheckOrderButton';
import './ItemList.css';
import ItemImage from './ItemImage/ItemImage';

export class ItemListView extends Component {    
    constructor(props) {
        super(props);
    }

    static defaultProps = {
        quantityHint: null,
        hideStockButton: false,
        buttonComponent: null
    }

    render() {

        var consumableText = "";
        if (this.props.item.individuallyBarcoded) {
            consumableText += "- Individually Barcoded ";
        }
        else if (!(this.props.item.measurementUnit === "Item")) {
            consumableText += "- Units: " + this.props.item.measurementUnit
        }

        return (
            <div className="item-list-container">
                <ItemImage imageId={this.props.item.imageId} />
                <div style={{ width: "100%"}}>
                    <div>
                        <Link to={"/item/view/" + this.props.item.id} style={{ textDecoration: "none" }}><p className="item-large-heading">
                            {this.props.quantityHint != null ? <font style={{ color: "red" }}><b>{this.props.quantityHint} x </b></font> : null}
                            {this.props.item.name}</p></Link>
                    </div>
                    <div style={{ display: "inline-block", width: "70%", minHeight: "92px" }}>
                        <hr style={{ margin: "2px 0 2px 0", width:"90%", color:"#999999"}} />
                        <p className="item-large-description"> {this.props.item.description}</p>                       
                        {"category" in this.props.item ? <p className="item-large-description"><b>Category:</b> {this.props.item.category.name} </p>: null}
                        <p className="item-large-description"><b>Id:</b> {this.props.item.id} {consumableText} |&nbsp;
                            {this.props.item.customLink1Name ? <a href={this.props.item.customLink1Url}>{this.props.item.customLink1Name}</a> : null}&nbsp;
                            {this.props.item.customLink2Name ? <a href={this.props.item.customLink2Url}>{this.props.item.customLink2Name}</a> : null}&nbsp;
                            {this.props.item.customLink3Name ? <a href={this.props.item.customLink3Url}>{this.props.item.customLink3Name}</a> : null}
                        </p>
                    </div>
                    <div style={{ display: "inline-block", overflow: "hidden", verticalAlign: "bottom", textAlign: "right", width: "30%" }}>
                        {this.props.hideStockButton ? null : <StockCheckOrderButton itemListing={this.props.item} />}
                        {this.props.buttonComponent}
                    </div>
                </div>
            </div>                
        );
    }
}

    
