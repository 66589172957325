import React, { Component } from 'react';
import { Modal } from './OrderRequestPopup/Modal';
import TriggerButton from './OrderRequestPopup/OrderButton';
import { ProjectPlaceLoginCheck } from './../Utils';

export class OrderRequestButton extends Component {
    state = { isShown: false, submitSuccess: false, submittedID: 'Error' };

    showModal = () => {
        var callbackURL = `/item/view/${this.props.itemListingId}`;
        if (ProjectPlaceLoginCheck(callbackURL)) {
            this.setState({ isShown: true }, () => {
                this.closeButton.focus();
            });
            this.toggleScrollLock();
        }        
    };
    closeModal = () => {
        this.setState({ isShown: false });
        this.TriggerButton.focus();
        this.toggleScrollLock();
    };
    onKeyDown = (event) => {
        if (event.keyCode === 27) {
            this.closeModal();
        }
    };
    onClickOutside = (event) => {
        //if (this.modal && this.modal.contains(event.target)) return;
        //this.closeModal();
    };

    toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };

    onSubmit = (event) => {
        event.preventDefault(event);
        this.sendCreateLocation(JSON.stringify({
            CreatedById: event.target.createdById.value,
            ItemListingId: event.target.itemListingId.value,
            DueDate: event.target.dueDate.value,
            Quantity: event.target.quantity.value,
            Comment: event.target.comment.value,
            ProjectId: event.target.projectId.value
        }));

        event.stopPropagation()
    };

    async sendCreateLocation(formData) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: formData
        };
        console.log(requestOptions);
        const response = await fetch(`api/ordering/create-request`, requestOptions).then((response) => response.json());

        this.setState({ submitSuccess: true, submittedID: response.id })

    }

    render() {
        return (
            <React.Fragment>
                <TriggerButton
                    showModal={this.showModal}
                    buttonRef={(n) => (this.TriggerButton = n)}
                    triggerText={this.props.triggerText}
                />
                {this.state.isShown ? (
                    <Modal
                        onSubmit={this.onSubmit}
                        modalRef={(n) => (this.modal = n)}
                        buttonRef={(n) => (this.closeButton = n)}
                        closeModal={this.closeModal}
                        onKeyDown={this.onKeyDown}
                        onClickOutside={this.onClickOutside}
                        itemListingId={this.props.itemListingId}
                        submitSuccess={this.state.submitSuccess}
                        submittedID={this.state.submittedID}
                        itemName={this.props.itemName}
                    />
                ) : null}
            </React.Fragment>
        );
    }
}

export default OrderRequestButton;