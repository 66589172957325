import React, { Component } from 'react';
import Popup from './Popup';

export class ItemImage extends Component {
    state = { isShown: false };

    showModal = () => {
        this.setState({ isShown: true }, () => {
            this.closeButton.focus();
        });
        this.toggleScrollLock();
    };
    closeModal = () => {
        this.setState({ isShown: false });
        this.toggleScrollLock();
    };
    onKeyDown = (event) => {
        if (event.keyCode === 27) {
            this.closeModal();
        }
    };
    onClickOutside = (event) => {
        if (this.modal && this.modal.contains(event.target)) return;
        this.closeModal();
        event.preventDefault();
    };

    toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };


    render() {

        if (typeof this.props.imageId !== 'undefined' && typeof this.props.imageId !== 'undefined' && this.props.imageId != null) {
            var thumbnailURL = "/item-images/thumbnail/".concat(this.props.imageId).concat(".jpg");
            var fullURL = "/item-images/highres/".concat(this.props.imageId).concat(".jpg");
        }
        else {
            var thumbnailURL = "/item-images/thumbnail/unknown.jpg";
        }

        return (
            <React.Fragment>
                <img onClick={this.showModal} src={thumbnailURL} style={{ objectFit: "contain", width: "120px", height: "120px" }} />
                {this.state.isShown ? (
                    <Popup
                        onSubmit={this.onSubmit}
                        modalRef={(n) => (this.modal = n)}
                        buttonRef={(n) => (this.closeButton = n)}
                        closeModal={this.closeModal}
                        onKeyDown={this.onKeyDown}
                        onClickOutside={this.onClickOutside}
                        imageURL={fullURL}
                    />
                ) : null}
            </React.Fragment>
        );
    }
}

export default ItemImage;