import React, { Component } from 'react';
import { OrderRequestsList } from './OrderListing/OrderRequestsList';

export class Ordering extends Component {

    render() {
        return (
            <div>
                <OrderRequestsList />
            </div>
        );
    }
}
