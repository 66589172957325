import React from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

const Trigger = ({ triggerText, buttonRef, showModal }) => {
    return (
        <Button
            variant="outline-secondary"
            style={{ height: '41px' }}
            ref={buttonRef}
            onClick={showModal}
            type={"button"}
        >
            {triggerText}
        </Button>
    );
};
export default Trigger;