export const QRURLRemover = (inputString) => {
    const startsWithURL = inputString.startsWith("URL:");
    if (startsWithURL) {
        return inputString.substring(4);
    }
    else {
        return inputString
    }
}

export const ProjectPlaceLoginCheck = async (postCallbackRedirectURL) => {
    const response = await fetch(`api/projectplace/check-auth`);
    const reply = await response.text();
    if (reply === 'false') {
        const authURLFetch = await fetch(`api/projectplace/get-auth-url?CallbackRedirectURL=${postCallbackRedirectURL}`);
        const authURL = await authURLFetch.text();
        window.location.replace(authURL);
    }
    return response;
}
