import React, { Component, useCallback } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { QRURLRemover } from './Utils';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';

export class WhereIsItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasResults: false, 
            loading: false,
            barcodeText: "",
            item: null
        }
        this.searchListing = this.searchListing.bind(this);
    }

    componentDidMount() {
        this.barcodeBar.focus();
    }

    async searchListing(event) {
        event.preventDefault();
        const response = await fetch(`api/items/get-item-from-barcode?barcode=${event.target["barcode"].value}`);
        const data = await response.json();
        this.setState({ item: data, loading: false, hasResults: true});
    }

    render() {
        const onBarcodeTextChange = (event) => {
                this.setState({ barcodeText: QRURLRemover(event.target.value) });
        }

        var searchListings;

        if (this.state.hasResults) {
            if (this.state.loading) {
                searchListings = <center><Spinner animation="border" variant="secondary" /></center>
            }
            else {
                searchListings = <center><hr /><h2><b>{this.state.item.itemLocation.barcode}</b> - {this.state.item.itemLocation.identifier}</h2><p>{this.state.item.itemLocation.description}</p></center>
            }
        }
        return (
            <div>
                <center>
                    <div style={{ display: 'inline-block', paddingLeft: "10px", verticalAlign: "bottom", height: "80px" }}>
                    
                        <form onSubmit={this.searchListing}>
                            Item Barcode: <input onChange={onBarcodeTextChange} value={this.state.barcodeText} id="barcode" autoComplete="off" size="30" style={{ height: "41px" }} ref={(n) => { this.barcodeBar = n; }} />
                            <Button type="submit" variant="outline-secondary" disabled={this.state.loading}>Submit</Button>
                        </form>
                    {searchListings}
                    </div>
                </center>
            </div>
        );
    }
}