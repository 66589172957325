import React, { Component, Button } from 'react';
import DropdownTreeSelectContainer from './DropDownContainer'
import { SupplierCreatorPopup } from './CreatorPopup/SupplierCreatorPopup';
import 'react-dropdown-tree-select/dist/styles.css'


export class SupplierSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryData: {
                label: 'loading...',
                value: 'loading...',
                isDefaultValue: true,
                disabled: true,
                children: []
            }
        };
    }

    static defaultProps = {
        onSelection: (id) => { },
        defaultValue: null
    }


    componentDidMount() {
        this.populateCategoryList();
    }

    async populateCategoryList() {
        const response = await fetch(`api/supplier/treeview`);
        const data = await response.json();
        console.log(this.props.defaultValue);
        if (this.props.defaultValue !== null) {
            var index = data.findIndex(x => parseInt(x.value) === this.props.defaultValue)
            data[index].isDefaultValue = true;
        }

        this.setState({ categoryData: data});
    }

    onChange = (currentNode, selectedNodes) => {
        if (selectedNodes.length > 0) {
            this.props.onSelection(selectedNodes[0].value);
        }
    }

    render() {
        const onSubmit = () => {
            this.populateCategoryList();
        };

        return (
            <div>
                <div style={{ display: 'inline-block' }}>
                    <DropdownTreeSelectContainer data={this.state.categoryData} onChange={this.onChange} mode={'radioSelect'} />
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top', paddingLeft: "5px" }}>
                    {this.props.allowCreate &&
                        <SupplierCreatorPopup triggerText={"Create New"} onSubmit={onSubmit} />}
                </div>
            </div>
        );
    }
}
