import React, { Component, Fragment } from 'react';
import { SupplierPopup } from "../../SupplierPopup/SupplierPopup"
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

export class SupplierListing extends Component {    
    constructor(props) {
        super(props);
        console.log(props)
        };

    render() { 

        return (
            <div style={{ position: "relative", border: "2px solid lightgrey", margin: "5px", padding: "5px", background: "#f4f4f4" }}>
                {(typeof this.props.onOrderButtonPress !== 'undefined' && typeof this.props.onOrderButtonPress !== 'undefined') ?
                    <span style={{ float: "left" }}><Button variant="success" size="sm" onClick={() => this.props.onOrderButtonPress(this.props.orderDetails.id)}>Order from here</Button></span>
                    :
                    null
                }
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <SupplierPopup supplierData={this.props.orderDetails.supplier} triggerText={this.props.orderDetails.supplier.name} />                    
                </div>
                
                <p className="item-large-description" style={{ fontSize: "130%"}}>
                    {this.props.orderDetails.modelName}</p>
                <p className="item-large-description">Supplier Model No: <b>{this.props.orderDetails.supplierModelNumber}</b></p>
                <p className="item-large-description">Manufacturer Model No: <b>{this.props.orderDetails.manufacturerModelNumber}</b></p>
                
                <p className="item-large-description">URL: <a href={this.props.orderDetails.url}>{this.props.orderDetails.url}</a></p>
                <hr />                
                <p className="item-large-description">

                    Min order: {this.props.orderDetails.minOrderQuantity} | Leadtime : {this.props.orderDetails.approximateLeadTime} | ~Price: &#163;{this.props.orderDetails.approximatePrice} {this.props.orderDetails.preferredSupplier ? <b> | Preferred Supl.</b> : null}
                    <span style={{ float: "right" }}>
                        ID: {this.props.orderDetails.id} <Link to={"/ordering/edit/" + this.props.orderDetails.id}><Button variant="secondary" size="sm">Edit</Button></Link>
                </span></p>
            </div>
        );
    }
}
