import React, { Component } from 'react';

export class DisplayUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            loading: true
        };
    }

    componentDidMount() {
        this.getUserData();
    }

    async getUserData() {
        const response = await fetch(`api/users/get-user?id=${this.props.userId}`);
        const data = await response.json();
        this.setState({ userData: data, loading: false});
    }
    
    render() {

        var text = `${this.props.userId}`
        if (this.state.loading === false) {
            text = this.state.userData.name;
        }

        return (
            <span>
                {text}
            </span>
        );
    }
}