import React, { Component } from 'react';
import { DisplayUser } from '../User/DisplayUser';

export class Popup  extends Component {    
    constructor(props) {
        super(props);
    }

    static defaultProps = {

        // Id,Name,Description,ContactName, ContactEmail, ContactPhone,DiscountAvailable,Notes,Address, Website,DmContact
        supplierData: () => {         
        }
    }

    render() {
        return (
            <div>
                <p>
                    <h2>{this.props.supplierData.name}</h2>
                    DM Contact: <b><DisplayUser userId={this.props.supplierData.dmContact} /></b><br />
                    Description: <b>{this.props.supplierData.description}</b><br />
                    <hr />
                    Contact Name: <b>{this.props.supplierData.contactName}</b><br />
                    Phone Number: <b>{this.props.supplierData.contactPhone}</b><br />
                    Email: <b>{this.props.supplierData.contactEmail}</b><br />
                    Website: <b>{this.props.supplierData.website}</b><br />
                    Address: <b>{this.props.supplierData.address}</b><br />
                    Account Type: <b>{this.props.supplierData.accountType}</b><br />
                    <hr />
                    Notes: <b>{this.props.supplierData.notes}</b><br />
                </p>
            </div>
        );
    }
}