import React, { Component, useCallback } from 'react';
import { CategorySelector } from './CategorySelector/CategorySelector';
import debounce from 'lodash.debounce';
import Spinner from 'react-bootstrap/Spinner';
import { ItemListView } from './ItemListing/ItemListView';
import { Buffer } from 'buffer';
import { QRURLRemover } from './Utils';
import { ManufacturerSelector } from './ManufacturerSelector/ManufacturerSelector';
import { useSearchParams } from "react-router-dom";

export class ItemSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedCategoryID: 0,
            selectedManufacturerId: 0,
            searchString: "",
            searchText: "",
            encodedString: "",
            itemListings: {}
        }
    }

    componentDidMount() {
        this.changeTextDebouncer = debounce(this.changeTextDebounced, 600);
        this.searchBar.focus();
    }

    changeTextDebounced = (text) => {
        var filteredString = QRURLRemover(text.target.value)
        this.setState({
            searchString: filteredString,
            encodedString: Buffer.from(filteredString).toString('base64')
        }, () => this.searchListing());
    }

    onCategorySelection = (id) => {
        this.setState({
            loading: true,
            selectedCategoryID: id
        }, () => this.searchListing());
    }

    onManufacturerSelection = (id) => {
        this.setState({
            loading: true,
            selectedManufacturerId: id
        }, () => this.searchListing());
    }

    async searchListing() {
        const response = await fetch(`api/items/search?categoryId=${this.state.selectedCategoryID}&manufacturerId=${this.state.selectedManufacturerId}&encodedSearchString=${this.state.encodedString}`);
        const data = await response.json();

        this.setState({ itemListings: data, loading: false });
    }

    renderSearchResults(searchResults) {
        return (
            <div>
                {searchResults.length > 0 ?
                    searchResults.map(searchResult => <ItemListView key={searchResult.id} item={searchResult} />)
                    :
                    <center><p>No Results</p></center>
                }
            </div>
        );
    }


    render() {

        const onChange = (event) => {
            var searchText = QRURLRemover(event.target.value)
            this.setState({ loading: true, searchText: searchText });
            this.changeTextDebouncer(event);
        };

        var searchListings = this.state.loading
            ? <center><Spinner animation="border" variant="secondary" /></center>
            : this.renderSearchResults(this.state.itemListings);

        var searchListings = <center><p>Start searching to list results</p></center>

        if (this.state.selectedCategoryID != 0 || this.state.selectedManufacturerId != 0 || this.state.searchString.length > 0) {
            if (this.state.loading) {
                searchListings = <center><Spinner animation="border" variant="secondary" /></center>
            }
            else {
                searchListings = this.renderSearchResults(this.state.itemListings);
            }
        }


        return (
            <div>
                
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: "20px" }}>
                    <div style={{ display: 'inline-block', paddingLeft: "10px", verticalAlign: "bottom", height: "80px" }}>
                        Text Search:<br /> <input onChange={onChange} value={this.state.searchText} id="searchBar" size="30" style={{ height: "41px" }} ref={(n) => { this.searchBar = n; }} />
                    </div>
                    <div style={{ display: 'inline-block', paddingLeft: "10px", verticalAlign: "bottom", height: "80px" }}>
                        Select Category <CategorySelector onSelection={this.onCategorySelection} />
                    </div>
                    <div style={{ display: 'inline-block', paddingLeft: "10px", verticalAlign: "bottom", height: "80px" }}>
                        Select Category <ManufacturerSelector onSelection={this.onManufacturerSelection} selectDefault={false}/>
                    </div>
                </div>
                <div style={{ width: "700px", margin: "0 auto" }}>
                    {searchListings}
                </div>
            </div>
        );
    }
}


//<SetPageURL selectedCategoryID={this.state.selectedCategoryID} selectedManufacturerId={this.state.selectedManufacturerId} encodedSearchText={this.state.encodedString} />
function SetPageURL(props) {
    let [searchParams, setSearchParams] = useSearchParams();
    setSearchParams({
        categoryId: props.selectedCategoryID,
        manufacturerId: props.selectedManufacturerId,
        search: props.encodedSearchText
    });
    return null;
}


