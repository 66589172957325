import React, { useEffect } from "react"
import { useParams,  useSearchParams, Link, useLocation} from 'react-router-dom';
import { useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';

import {
    MaterialReactTable,
    useMaterialReactTable
} from 'material-react-table';


export function DatabaseView() {
    const { query } = useParams();    
    const [searchParams] = useSearchParams();
    const [pageLoading, setPageLoading] = useState(true);    
    const [fetchedColumns, setFetchedColumns] = useState([]);
    const [links, setLinks] = useState({});
    const [data, setData] = useState([]);
    const [pageTitle, setPageTitle] = useState("");
    const location = useLocation();

    useEffect(() => {
        var urlQuery = '/api/databaseviewer/';        
        if (query !== undefined) {
            urlQuery += query;
            if (searchParams.size > 0) {
                urlQuery += "?";
                searchParams.forEach(function (value, key) {
                    urlQuery += key + "=" + value + "&"
                });
            }            
        }

        FetchData(urlQuery);        
    }, [query, searchParams, location.pathname]);

    const FetchData = async (urlQuery) => {        
        console.log(urlQuery);
        await setPageLoading(true);
        try {
            const response = await fetch(urlQuery);
            const data = await response.json();
            await setLinks(data["links"]);
            await setFetchedColumns(data["columns"]);
            await setData(data["data"]);
            await setPageTitle(data["pageTitle"])
            await setPageLoading(false);
        } catch (error) {
            console.log("error", error);
        }
    };

    const columns = useMemo(
        () =>
            fetchedColumns.length
                ? fetchedColumns.map((columnId) => {

                    if (columnId in links) {
                        //Column is a link
                        return ({
                            header: columnId,
                            accessorKey: columnId,
                            id: columnId,
                            size: columnId === "Id" ? "5" : "",
                            Cell: ({ cell }) => (
                                <Link to={links[columnId].inPageLink ? "/database-view/" + links[columnId].url + cell.getValue() : links[columnId].url + cell.getValue()}>
                                    {links[columnId].overrideText ? links[columnId].text : cell.getValue()}
                                </Link>)
                        })
                    } 
                    //A regular column, no link
                    else {
                        return ({
                            header: columnId,
                            accessorKey: columnId,
                            id: columnId,
                            size: columnId === "Id" ? "5" : ""
                        });
                    }

                })
                : [],
        [fetchedColumns],

    );
    
    const table = useMaterialReactTable({
        columns,
        data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        enableRowSelection: false, //enable some features
        enableColumnOrdering: true, //enable a feature for all columns
        enableGlobalFilter: false, //turn off a feature
        enablePagination: false,
        initialState: { density: 'compact' },
        
    });    

    return(
        <div>
            {pageLoading ?
                <center><Spinner as="span" variant="primary" animation="border" size="lg" role="status" aria-hidden="true" /></center>
                :
                <div>
                    <h3><Link to="/database-view/">DatabaseViewer</Link> - <b>{pageTitle}</b></h3>
                <MaterialReactTable table={table} />                
               </div>
            }
        </div>
    );
}