import React, { Component } from 'react';

export class StockLocationListing extends Component {
    constructor(props) {
        super(props);
    };

    render() {

        var background = "#f4f4f4";

        if (this.props.stockData.quantity > 0) {
            background = "#e8f7e4";
        }

        return (
            <div style={{ position: "relative", border: "2px solid lightgrey", margin: "5px", padding: "5px", background: background }}>
                <p className="item-large-heading">
                    <b>{this.props.stockData.location.identifier}</b> [{this.props.stockData.location.barcode}]
                    <span style={{ float: "right", textAlign: "right", alignContent: "top" }}>
                        Qty: {this.props.stockData.quantity}
                    </span>
                    <br />
                    {this.props.stockData.store.name}</p>
                <p className="item-large-description">
                    Description: {this.props.stockData.location.description}</p>
            </div>
        );
    }
}
