import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
        <div>
            <NavMenu userData={this.props.userData} />

            {this.props.fullWidth === true ? 
            <Container style={{ maxWidth: "100%" }}>
                {this.props.children}
            </Container>
            :
            <Container>
                {this.props.children}
            </Container>
            }
      </div>
    );
  }
}
