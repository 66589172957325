import React, { Component } from 'react';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { ItemCreationForm } from './components/ItemCreator/ItemCreatorForm';
import { CreateOrderDetailsFormWrapper, EditOrderDetailsFormEditWrapper } from './components/OrderListing/OrderDetailsFormWrapper';
import './custom.css'
import { ItemSearch } from './components/ItemSearch';
import { Ordering } from './components/Ordering';
import { ItemListingSummaryWrapper } from './components/ItemListing/ItemListingSummaryWrapper';
import { ItemListingPrintWrapper } from './components/ItemListing/ItemListingPrintWrapper';
import { Checkout } from './components/Checkout';
import { GoodsIn } from './components/GoodsIn';
import { Fragment } from 'react';
import { NoAccess } from './components/NoAccess';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ItemCreatorFormWrapper } from './components/ItemCreator/ItemCreatorFormWrapper';
import { ViewIndividualOrderRequestWrapper } from './components/ViewIndividualOrderRequestWrapper';
import { StockCheck } from './components/StockCheck';
import { ModifyLocation } from './components/ModifyLocation';
import { WhereIsItem } from './components/WhereIsItem'
import { DatabaseView } from './components/DatabaseViewer/DatabaseView'

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = { loading: true, userData: { userLoggedIn: false, userDisplayName: "", userFirstName: "", userLoading: true, userHasAccess: false} };
    }

    componentDidMount() {
        this.populateLoginData();
    }

    async populateLoginData() {
        const response = await fetch('api/logindetails');
        const data = await response.json();

        this.setState({
            userData: {
                userLoggedIn: data.userLoggedIn,
                userHasAccess: data.userHasAccess,
                userDisplayName: data.userDisplayName,
                userFirstName: data.userFirstName,
                userAvatar: data.userAvatar,
                showAlternateLogin: data.showAlternateLogin,
                userLoading: false
            }
        });
    }

    render() {
        return (
            <Fragment>
                {this.state.userData.userLoading ?
                    <center><Spinner as="span" variant="primary" animation="border" size="lg" role="status" aria-hidden="true" /></center>
                    :                    
                        <Routes>
                            {this.state.userData.userHasAccess ? <Fragment>
                                <Route index element={<Layout userData={this.state.userData}><Home userData={this.state.userData} /></Layout>} />
                                <Route path="/search" element={<Layout userData={this.state.userData}><ItemSearch /></Layout>} />
                                <Route path="/item/create" element={<Layout userData={this.state.userData}><ItemCreationForm /></Layout>} />
                                <Route path="/item/edit/:id" element={<Layout userData={this.state.userData}><ItemCreatorFormWrapper /></Layout>} />
                                <Route path="/item/view/:id" element={<Layout userData={this.state.userData}><ItemListingSummaryWrapper /></Layout>} />
                                <Route path="/item/print/:id" element={<ItemListingPrintWrapper />} />
                                <Route path="/item/move" element={<Layout userData={this.state.userData}><ModifyLocation /></Layout>} />
                                <Route path="/item/locate" element={<Layout userData={this.state.userData}><WhereIsItem /></Layout>} />
                                <Route path="/ordering/create/:id" element={<Layout userData={this.state.userData}><CreateOrderDetailsFormWrapper /></Layout>} />
                                <Route path="/ordering/edit/:id" element={<Layout userData={this.state.userData}><EditOrderDetailsFormEditWrapper /></Layout>} />
                                <Route path="/ordering/view/:id" element={<Layout userData={this.state.userData}><ViewIndividualOrderRequestWrapper /></Layout>} />
                                <Route path="/ordering" element={<Layout userData={this.state.userData}><Ordering /></Layout>} />
                                <Route path="/goods-in" element={<Layout userData={this.state.userData}><GoodsIn /></Layout>} />
                                <Route path="/check-out" element={<Layout userData={this.state.userData}><Checkout /></Layout>} />
                                <Route path="/stock-check" element={<Layout userData={this.state.userData}><StockCheck /></Layout>} />                                                                                                
                                <Route path="/database-view" element={<Layout userData={this.state.userData} fullWidth={false}><DatabaseView/></Layout>} />
                                <Route path="/database-view/:query" element={<Layout userData={this.state.userData} fullWidth={true}><DatabaseView/></Layout>} />
                                </Fragment>
                                :
                            <Route path="*" element={<Layout userData={this.state.userData}><NoAccess userData={this.state.userData} /></Layout>} />}
                        </Routes>                    
                }
            </Fragment>
        );
    }
}
