import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import { OrderRequestSummaryListing } from './Listings/OrderRequestSummaryListing';


export class ItemOrderRequestsList extends Component {

    constructor(props) {
        super(props);
        this.state = { orderRequestData: [], loading: true };
    }

    componentDidMount() {
        this.populateOrderRequestList();
    }

    renderOrderRequestList(orderRequestData) {
        return (
            <div id="flex-container">
                {orderRequestData.map(orderRequestData => <OrderRequestSummaryListing orderRequestData={orderRequestData} />)}
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderOrderRequestList(this.state.orderRequestData);

        return (
            <div style={{padding: "10px"}} >
                <h3>Order Requests</h3>                
                {contents}
            </div>
        );
    }

    async populateOrderRequestList() {
        const response = await fetch(`api/ordering/list-item-order-requests?itemListingId=${this.props.itemListingId}`);
        const data = await response.json();
        this.setState({ orderRequestData: data, loading: false});
    }
}