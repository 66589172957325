import React from 'react';
import './GreenTick.css';

const GreenTick = () => {
    return (
        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <circle className="checkmark__circle" cx="10" cy="10" r="10" fill="none" />
            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
    );
};
export default GreenTick;