import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import GreenTick from './GreenTick/GreenTick';
import LocationCreatorPopup from './LocationSelector/CreatorPopup/LocationCreatorPopup';
import { QRURLRemover } from './Utils';

export class ModifyLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formSubmitted: false,
            buttonText: "Submit form",
            locationBarcodeText: "",
            itemBarcodeText: "",
            itemFound: false,

            locationBarcode: "",
            locationIdentifier: "",
            locationFound: false,
            locationValid: false,
            locationText: "",
            locationId: -1,

            scannedItemBarcodes: []
        }
    }

    componentDidMount() {
        this.itemTextDebouncer = debounce(this.itemTextDebounced, 500);
        this.locationTextDebouncer = debounce(this.locationTextDebounced, 500);
        this.itemBarcodeTextbox.focus();
    }

    itemTextDebounced = (text) => {
        this.itemTextDebouncer.cancel();
        this.setState({
            itemValidated: false
        });

        var barcode = QRURLRemover(text.target.value);

        if (text.target.value.startsWith("L-"))
        {
            this.setState({
                locationBarcodeText: barcode,
                itemBarcodeText: ""
            }, () => this.checkLocationBarcode(barcode));
        }
        else
        {
            //Treat as a normal item barcode
            if (barcode.length >= 5) {
                this.checkItemBarcode(barcode);
            }
        }
    }

    locationTextDebounced = (text) => {
        var barcode = QRURLRemover(text.target.value);
        this.setState({
            locationValid: false,
            locationText: barcode
        });

        if (text.target.value.length >= 4) {
            //Submit location check
            this.checkLocationBarcode(barcode);
            this.setState({ locationHasValue: true });
        }
        else {
            this.setState({ locationValid: false, locationHasValue: false });
        }
    }

    async checkItemBarcode(barcode) {

        var URL = `api/items/verify-item-barcode?barcode=${barcode}`;
        const response = await fetch(URL);
        const data = await response.json();

        if (data.itemFound) {
            this.setState({
                itemFound: true,
                scannedItemBarcodes: [...this.state.scannedItemBarcodes, data.itemBarcode],
                itemBarcodeText: ""
            }, ()=> this.itemBarcodeTextbox.focus());
        }
    }

    async checkLocationBarcode(barcode) {
        const response = await fetch(`api/location/verify-location-barcode?barcode=${barcode}`);
        const data = await response.json();

        this.setState({
            locationFound: data.locationFound,
            locationId: data.locationId
        });
    }

    isInt(value) {
        return !isNaN(value) &&
            parseInt(Number(value)) == value &&
            !isNaN(parseInt(value));
    }

    isFloat(value) {
        return !isNaN(value) &&
            parseFloat(Number(value)) == value &&
            !isNaN(parseFloat(value));
    }

    submitHandler(e) {
        e.preventDefault();
    }

    submitForm() {
        this.sendModifyLocation(JSON.stringify({
            itemBarcodes: this.state.scannedItemBarcodes,
            locationId: this.state.locationId
        }));
    };

    async sendModifyLocation(formData) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: formData
        };

        const response = await fetch(`api/items/modify-location`, requestOptions)
        const data = await response.json();
        if (data === true) {
            alert("Location Updated");
        }
        else {
            alert("Update FAILED");
        }
        
    }


    render() {
        const submitButtonClicked = () => {
            this.setState({
                buttonText: "Please wait...",
                formSubmitted: true
            });
            this.submitForm();
        }

        const reCheckLocationBarcode = () => {
            this.checkLocationBarcode(this.state.locationText);
        }

        const onItemTextChange = (event) => {
            this.setState({
                itemBarcodeText: QRURLRemover(event.target.value)
            })

            if (event.target.value.length == 0 && this.state.scannedItemBarcodes > 0) {
                return;
            }
            else {
                //Don't update if text is empty and barcodes have been scanned
                this.itemTextDebouncer(event);
            }
        };

        const onLocationTextChange = (event) => {
            this.setState({
                locationBarcodeText: event.target.value
            })
            this.locationTextDebouncer(event);
        };

        const handleEnterOnItemBarcode = (event) => {
            if (event.key.toLowerCase() === "enter") {
                this.setState({ itemEnterKeyPressed: true }, function () { this.itemTextDebounced(event) });
            }
        };

        
        //ItemTextBox
        var itemTextBox = "";
        if (!this.state.itemFound && this.state.itemBarcodeText.length > 0) {
            var itemTextBox = <p>❌ Item does not exist</p>
        }

        //Location
        var locationTextBox = "";
        if (this.state.locationFound) {
            var textWidth = Math.min(this.state.locationIdentifier.length, 30) + "ch"
            var locationTextBox =
                <div style={{ display: "flex", paddingLeft: "10px", verticalAlign: "middle" }}>
                    <p style={{ width: textWidth, verticalAlign: "bottom", fontSize: "14px", margin: "0px", padding: "0px", paddingTop: "5px" }}>{this.state.locationIdentifier}</p>
                    <GreenTick />
                </div>
        }
        else if (this.state.locationValid) {
            var locationTextBox =
                <div style={{ display: "flex", paddingLeft: "10px", verticalAlign: "middle", width: "200px" }}>
                    <LocationCreatorPopup triggerText={"Create new"} locationBarcode={this.state.locationText} onSubmit={reCheckLocationBarcode} />
                </div>
        }
        else if (this.state.locationHasValue) {
            var locationTextBox =
                <p style={{ width: "20ch", verticalAlign: "bottom", fontSize: "14px", margin: "0px", padding: "0px", paddingTop: "5px" }}>❌ <b>Invalid location</b></p>
        }


        return (
            <div>
                <h2>Move item location:</h2>
                <form onSubmit={this.submitHandler}>
                    <div className="form-group">
                        <label htmlFor="itemBarcode">Item barcode</label>
                        {this.state.scannedItemBarcodes.map(barcode => <div key={barcode} style={{ display: "flex" }}><input className="form-control" id="itemBarcode-TEXTAREA" value={barcode} disabled /><div style={{ paddingRight: "10px" }}><GreenTick /></div></div>)}
                        <div style={{ display: "flex" }}>
                            <input className="form-control" id="itemBarcode" onChange={onItemTextChange} value={this.state.itemBarcodeText} ref={(n) => { this.itemBarcodeTextbox = n; }} onKeyPress={handleEnterOnItemBarcode} />
                            {itemTextBox}
                        </div>                    
                    </div>

                    <div className="form-group">
                        <label htmlFor="locationBarcode">Location Barcode</label>
                        <div style={{ display: "flex" }}>
                            <input className="form-control" id="locationBarcode" onChange={onLocationTextChange} required disabled={!(this.state.scannedItemBarcodes.length > 0)} ref={(n) => { this.locationBarcodeTextbox = n; }} value={this.state.locationBarcodeText} />
                            {locationTextBox}
                        </div>
                    </div>

                    <div className="form-group">
                        <button disabled={this.state.formSubmitted || !(this.state.scannedItemBarcodes.length > 0) || !(this.state.locationFound)} onClick={submitButtonClicked} className="form-control btn btn-primary" type="submit">
                            {this.state.buttonText}
                        </button>
                    </div>
                </form>
            </div>            
        );
    }
}