import React, { Component } from 'react';
import { GoodsInForm } from './GoodsIn/GoodsInForm';
import { OrderList } from './OrderListing/OrderSummaryList';

export class GoodsIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goodsInKey: 0
        }
        this.onGoodsInSubmit = this.onGoodsInSubmit.bind(this);
    }

    onGoodsInSubmit = () => {
        this.setState({
            goodsInKey: new Date().getTime()
        });
    }

    render() {
        return (
            <div>
                <GoodsInForm key={this.state.goodsInKey} onSubmit={this.onGoodsInSubmit}/>
                <OrderList />
            </div>
        );
    }
}
