import React, { Component, useCallback } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { QRURLRemover } from './Utils';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';

export class StockCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasResults: false, 
            loading: false,
            locationString: "",
            barcodeText: "",
            itemListings: []
        }
        this.searchListing = this.searchListing.bind(this);
        this.onSubmitItem = this.onSubmitItem.bind(this);
    }

    componentDidMount() {
        this.searchBar.focus();
    }

    async searchListing(event) {
        event.preventDefault();
        const response = await fetch(`api/items/get-all-items-at-location?barcode=${event.target["searchBar"].value}`);
        const data = await response.json();
        this.setState({ itemListings: data, loading: false, hasResults: true }, () => this.barcodeBar.focus());        
    }

    onSubmitItem(event){
        event.preventDefault();
        var searchText = QRURLRemover(event.target["barcode"].value)
        if (!this.deleteEntry(searchText)) {
            this.setState({ barcodeText: "" }, () => alert(`Item with barcode "${searchText}" was not found in this location`));
        }
        else
        { this.setState({ barcodeText: "" }); }
        
    };

    groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }


    renderSearchResults(searchResults) {
        const groupedResults = this.groupBy(searchResults, x => x.itemListingId);
        const groupArray = Array.from(groupedResults, ([name, value]) => (value));
        return (
            <div>
                {searchResults.length > 0 ?
                    groupArray.map(value => this.renderItems(value))
                    :
                    <center><p>No Results</p></center>
                }
            </div>
        );
    }

    renderItems(itemListings) {
        return (
            <div>
                Item: <b>{itemListings[0]["itemListing"]["name"]}</b>
                <Table striped bordered hover>
                    <thead>
                        <tr><td>Item Id</td><td>Barcode</td><td>Quantity</td></tr>
                    </thead>
                    <tbody>
                        {itemListings.map(item => <tr><td>{item["id"]}</td><td>{item["barcode"]}</td><td>{item["amountInStock"]}</td></tr>)}
                    </tbody>
                </Table>
            </div>
        );
    }

    deleteEntry(barcode) {
        var index = this.state.itemListings.findIndex(x => x.barcode === barcode);

        if (index >= 0) {
            let items = [...this.state.itemListings];
            items.splice(index, 1);
            this.setState({ itemListings: items });
            return true;
        }
        else {
            return false;
        }
    }
    

    render() {
        const onBarcodeTextChange = (event) => {
                this.setState({ barcodeText: QRURLRemover(event.target.value) });
        }

        var searchListings;

        if (this.state.hasResults) {
            if (this.state.loading) {
                searchListings = <center><Spinner animation="border" variant="secondary" /></center>
            }
            else {
                searchListings = this.renderSearchResults(this.state.itemListings);
            }
        }

        if (this.state.hasResults) {
            return (
                <div>
                    <center>
                        <div style={{ display: 'inline-block', paddingLeft: "10px", verticalAlign: "bottom", height: "80px" }}>
                        <form onSubmit={this.onSubmitItem}>
                                Item Barcode: <input onChange={onBarcodeTextChange} value={this.state.barcodeText} id="barcode" autoComplete="off" size="30" style={{ height: "41px" }} ref={(n) => { this.barcodeBar = n; }} />
                                <Button type="submit" variant="outline-secondary" disabled={this.state.loading}>Submit</Button>
                            </form>
                        
                        </div>
                    </center>
                    {searchListings}
                </div>
            );
        }
        else {
            return (                
                <center>
                    <div style={{ display: 'inline-block', paddingLeft: "10px", verticalAlign: "bottom", height: "80px" }}>
                        <form onSubmit={this.searchListing}>
                            Location Barcode: <input id="searchBar" size="30" style={{ height: "41px" }} ref={(n) => { this.searchBar = n; }} />
                            <Button type="submit" variant="outline-secondary" disabled={this.state.loading}>Submit</Button>
                        </form>                    
                    </div>                             
                </center>
            );
        }                
    }
}