import React, { Component } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { Modal } from './Modal';
import TriggerButton from './TriggerButton';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export class GoodsInPopup extends Component {
    state = { isShown: false };
    showModal = () => {
        this.setState({ isShown: true });
        this.toggleScrollLock();
    };
    closeModal = () => {
        this.setState({ isShown: false });
        this.TriggerButton.focus();
        this.toggleScrollLock();
    };
    onKeyDown = (event) => {
        if (event.keyCode === 27) {
            this.closeModal();
        }
    };
    onClickOutside = (event) => {
        //if (this.modal && this.modal.contains(event.target)) return;
        //this.closeModal();
    };

    onSubmit = (amount) => {
        this.props.onSubmit(amount);
        this.closeModal();
    }

    toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };

    onRequestDelete() {
        const response = fetch(`api/ordering/cancel-order?orderId=${this.props.orderId}`).then(this.props.onSubmit(this.props.amount));
    }

    deleteConfirmationButton = () => {
        confirmAlert({
            title: 'Cancel order?',
            message: 'Do you want to cancel outstanding items for order: \"' + this.props.itemName + '\"?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.onRequestDelete(),
                    className: 'btn btn-danger'
                },
                {
                    label: 'No',
                    className: 'btn btn-primary'
                }
            ]
        });
    };

    render() {
        return (
            <React.Fragment>
                <ButtonGroup>
                <TriggerButton
                        showModal={this.showModal}
                        buttonRef={(n) => (this.TriggerButton = n)}
                        triggerText={this.props.triggerText}
                        buttonVariant={this.props.buttonVariant}
                    />

                    {this.props.orderId != null ? <Button variant="danger" onClick={this.deleteConfirmationButton} type={"button"}>🗑️</Button> : null}
                </ButtonGroup>
                {this.state.isShown ? (
                    <Modal
                        onSubmit={this.onSubmit}
                        modalRef={(n) => (this.modal = n)}
                        buttonRef={(n) => (this.closeButton = n)}
                        closeModal={this.closeModal}
                        onKeyDown={this.onKeyDown}
                        onClickOutside={this.onClickOutside}
                        orderId={this.props.orderId}
                        itemName={this.props.itemName}
                        itemListingId={this.props.itemListingId}
                        itemDimensions={this.props.itemDimensions}
                        measurementUnit={this.props.measurementUnit}
                    />
                ) : null}
            </React.Fragment>
        );
    }
}

export default GoodsInPopup;