import React from "react";
import ImageUploading from "react-images-uploading";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function ImageUpload(props) {
    const { onImageUpload, ...restProps } = props;
    const [images, setImages] = React.useState([]);
    const maxNumber = 69;

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);
        if (imageList.length > 0)
        {
            onImageUpload(imageList[0].data_url);
        }
    };

    return (
        <div>
            <ImageUploading
                multiple={false}
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemove,
                    isDragging,
                    dragProps
                }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        {images.length === 0 &&
                            <Button
                                variant="outline-info" type={"button"}

                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                            >
                                Click or Drop here
                            </Button>
                         }
                        {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                                <div>                                    
                                    <Button variant="outline-danger" type={"button"} onClick={() => onImageRemove(index)}>Remove</Button>
                                </div>
                                <img src={image.data_url} alt="" width="200" />
                            </div>
                        ))}
                    </div>
                )}
            </ImageUploading>
        </div>
    );
}