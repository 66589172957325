import React, { Component } from 'react';

export class QuantityForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formSubmitted: false,
        }
        this.submitForm = this.submitForm.bind(this);
    }

    static defaultProps = {
        onSubmit: (amount) => { },
    }

    componentDidMount() {
        this.quantityTextbox.focus();
    }

    submitForm(e) {
        e.preventDefault();
        this.props.onSubmit(e.target.amount.value);
    };

    render() {

        var regexPattern = ""
        var labelText = ""
        var defaultValue = ""
        var step = ""
        var maxAmount = this.props.maxAmount
        var disableText = false;

        if (this.props.measurementUnit === "Item") {
            labelText = <label htmlFor="amount">Number of items taken (max: {this.props.maxAmount} {this.props.measurementUnit}s)</label>
            regexPattern = "\d+";
            defaultValue = "1";
            step = "1";
        }
        else if (this.props.measurementUnit === "Individual")
        {
            labelText = <label htmlFor="amount">Items need to be individually barcoded. Quantity registered:</label>
            regexPattern = "\d+";
            defaultValue = "1";
            step = "1";
            maxAmount = "1";
            disableText = true;
        }
        else {
            labelText = <label htmlFor="amount">Amount taken (max: {this.props.maxAmount} {this.props.measurementUnit})</label>
            regexPattern = "\d+\.?\d*";
            step = "any";
        }
 
        return (
            <form onSubmit={this.submitForm}>
                <h3><center>Select Quantity</center></h3>                
                <p>Item name: <b>{this.props.itemName}</b></p>
                <div className="form-group">
                    {labelText}
                    <input disabled={disableText} type="number" min="0" max={this.props.maxAmount} step={step} pattern={regexPattern} className="form-control" id="amount" defaultValue={defaultValue} ref={(n) => { this.quantityTextbox = n; }} required />
                </div>
                <br />
                <div className="form-group">
                    <button disabled={this.state.formSubmitted} className="form-control btn btn-primary" type="submit">
                        Check-out!
                    </button>
                </div>
            </form>
        );
    }
}