import React from 'react';
import './NavMenu.css';

export class SignInSignOutButton extends React.Component {

    constructor(props) {
        super(props);        
    }

    async onTabletSignout() {
        const response = await fetch(`api/users/tablet-signout`);
        window.location.reload(true);
    }
    
    render() {        

        if (this.props.userData.showAlternateLogin) {
            if (this.props.userData.userLoggedIn) {
                return (
                    <a href="#" onClick={() => this.onTabletSignout()}><img style={{ borderRadius: "50%", width: "80%" }} src={this.props.userData.userAvatar} title={this.props.userData.userDisplayName + " (Tablet Login) Click To sign-out"} /></a>
                );
            }
            else {
                return (
                    <a className="text-dark nav-link" href="/"> Sign-in</a>
                );
            }
        }
        else {
            if (this.props.userData.userLoggedIn) {
                return (
                    <a href="MicrosoftIdentity/Account/SignOut"><img style={{ borderRadius: "50%", width: "80%" }} src={this.props.userData.userAvatar} title={this.props.userData.userDisplayName + " Click To sign-out"} /></a>
                );
            } else {
                return (
                    <a className="text-dark nav-link" href="/MicrosoftIdentity/Account/SignIn"> Sign-in</a>
                );
            }
        }        
    }
}
