import React, { Component } from 'react';
import { UserSelector } from '../../UserSelector/UserSelector';
import 'bootstrap/dist/css/bootstrap.min.css';

export class Form  extends Component {    
    constructor(props) {
        super(props);
        this.state = { selectedID: 0, formSubmitted: false, buttonText: "Submit" };
    } 

    static defaultProps = {
        onSelection: () => { }
    }

    onCategorySelection = (id) => {
        this.setState({
            selectedID: id
        });
    }

    onSubmit = (event) => {
        event.preventDefault(event);
        this.sendCreateCategory(JSON.stringify({
            Name: event.target.name.value,
            Description: event.target.description.value,
            ContactName: event.target.contactName.value,
            ContactEmail: event.target.contactEmail.value,
            ContactPhone: event.target.contactPhone.value,
            Address: event.target.address.value,
            Website: event.target.website.value,
            Notes: event.target.notes.value,
            DmContact: event.target.dmContact.value,
            AccountType: event.target.accountType.value,
        }));

        event.preventDefault();
        event.stopPropagation()
    };

    async sendCreateCategory(formData) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: formData
        };
        console.log(requestOptions);
        const response = await fetch(`api/supplier/create`, requestOptions).then((response) => response.json());
        this.props.onSubmit();
    }

    render() {

        const submitButtonClicked = () => {
            
            this.setState({
                buttonText: "Please wait..."
                });
                
        }

        return (
            <form onSubmit={this.onSubmit}>
                <h3><center>Create a new supplier</center></h3>
                <div className="form-group">
                    <label htmlFor="name">Supplier/Company Name</label>
                    <input className="form-control" id="name" required />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea className="form-control" id="description"/>
                </div>
                <div>
                    <div className="form-group" style={{ display: 'inline-block', width: "33%" }}>
                        <label htmlFor="contactName">Contact Name</label>
                        <input className="form-control" id="contactName" />
                    </div>
                    <div className="form-group" style={{ display: 'inline-block', paddingLeft: "10px", width: "34%" }}>
                        <label htmlFor="contactEmail">Contact Email</label>
                        <input className="form-control" id="contactEmail" />
                    </div>
                    <div className="form-group" style={{ display: 'inline-block', paddingLeft: "10px", width: "33%" }}>
                        <label htmlFor="contactPhone">Contact Phone</label>
                        <input className="form-control" id="contactPhone" />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <textarea className="form-control" id="address" />
                </div>
                <div className="form-group">
                    <label htmlFor="website">Website</label>
                    <input className="form-control" id="website" />
                </div>
                <div className="form-group">
                    <label htmlFor="notes">Account Type</label>
                    <select class="form-select" name="accountType" id="accountType">
                        <option value="Purchase Order">Purchase Order</option>
                        <option value="Credit Card">Credit Card</option>
                        <option value="Cash / Proforma">Cash / Proforma</option>
                        <option value="Unknown">Unknown</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="notes">Misc Notes</label>
                    <textarea className="form-control" id="notes" />
                </div>
                <div className="form-group">
                    <label htmlFor="parent_category">DM Contact / Owner</label>
                    <UserSelector onSelection={this.onCategorySelection} />
                    <input type="hidden" id="dmContact" value={this.state.selectedID} />
                </div>
                <div className="form-group">
                    <button disabled={this.state.formSubmitted} onClick={submitButtonClicked} className="form-control btn btn-primary" type="submit">
                        {this.state.buttonText}
                    </button>
                </div>
            </form>
        );
    }
}