import React, { Component } from 'react';
import { OrderingOptions } from '../OrderListing/OrderingOptions';
import { StockLocations } from '../StockOrderButton/StockLocations';
import { ItemListView } from './ItemListView';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

export class ItemListingSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemData: {},
            loading: true
        };
    }

    componentDidMount() {
        this.populateItemDetails();
    }

    async populateItemDetails() {
        const response = await fetch(`api/items/get-item?itemListingId=${this.props.itemListingId}`);
        const data = await response.json();
        this.setState({ itemData: data, loading: false });
    }

    render() {

        if (this.state.loading) {
            return (
                <div>
                    Loading...
                </div>
            );
        }
        else {
            return (
                <div style={{ width: "700px" }}>
                    <h2>Item Summary: <span style={{ float: "right", textAlign: "right" }}> <Link to={"/item/print/" + this.props.itemListingId}><Button variant="secondary">Label</Button></Link> <Link to={"/item/edit/" + this.props.itemListingId}><Button variant="secondary">Edit</Button></Link></span></h2>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <ItemListView item={this.state.itemData} />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <OrderingOptions itemListingId={this.props.itemListingId} />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <StockLocations itemListingId={this.props.itemListingId} />
                    </div>
                </div>
            );
        }
    }
}
