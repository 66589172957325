import React from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

const Trigger = ({ triggerText, buttonRef, showModal, buttonVariant }) => {
    return (
        <Button
            variant={buttonVariant}
            ref={buttonRef}
            onClick={showModal}
            type={"button"}
        >
            {triggerText}
        </Button>
    );
};
export default Trigger;