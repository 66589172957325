import React, { Component } from 'react';
import { StoresSelector } from '../../StoresSelector/StoresSelector';

export class Form  extends Component {    
    constructor(props) {
        super(props);
        this.state = { selectedID: 0, formSubmitted: false, storesSelected:false, buttonText: "Submit" };
    } 

    static defaultProps = {
        onSelection: () => { },
        locationBarcode: ""
    }

    onCategorySelection = (id) => {
        this.setState({
            selectedID: id
        });
        if (id > 0) {
            this.setState({ storesSelected: true });
        }
        else {
            this.setState({ storesSelected: false });
        }
    }

    onSubmit = (event) => {
        event.preventDefault(event);
        this.sendCreateLocation(JSON.stringify({
            StoreId: event.target.storeId.value,
            Identifier: event.target.identifier.value,
            Barcode: event.target.barcode.value,
            Description: event.target.description.value
        }));

        event.preventDefault();
        event.stopPropagation()
    };

    async sendCreateLocation(formData) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: formData
        };
        console.log(requestOptions);
        const response = await fetch(`api/location/create-location`, requestOptions).then((response) => response.json());
        this.props.onSubmit();
    }

    render() {

        const submitButtonClicked = () => {
            
            this.setState({
                buttonText: "Please wait..."
                });
                
        }

        return (
            <form onSubmit={this.onSubmit}>
                <h3><center>Create a parts location</center></h3>
                <div className="form-group">
                    <label htmlFor="parent_category">Stores</label>
                    <StoresSelector onSelection={this.onCategorySelection} allowCreate={true} />
                    <input type="hidden" id="storeId" value={this.state.selectedID} />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Identifier</label>
                    <input className="form-control" id="identifier" required />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea className="form-control" id="description"/>
                </div>
                <div className="form-group">
                    <label htmlFor="website">Barcode</label>
                    <input className="form-control" id="barcode" defaultValue={this.props.locationBarcode} required />
                </div>                                
                <div className="form-group">
                    <br />
                    <button disabled={this.state.formSubmitted && this.state.storesSelected} onClick={submitButtonClicked} className="form-control btn btn-primary" type="submit">
                        {this.state.buttonText}
                    </button>
                </div>
            </form>
        );
    }
}