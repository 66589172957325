import React, { Component } from 'react';
import { UserSelector } from '../../UserSelector/UserSelector';
import { ProjectSelector } from '../../ProjectSelector/ProjectSelector';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

export class Form  extends Component {    
    constructor(props) {
        super(props);
        this.state = { selectedID: 0, selectedProjectID: -1, dueDateSet: false, formSubmitted: false, buttonText: "Submit" };
    }

    static defaultProps = {
        onSelection: () => { },
        itemName: ""
    }

    onCategorySelection = (id) => {
        this.setState({
            selectedID: id
        });
    }

    onProjectSelection = (id) => {
        this.setState({ selectedProjectID: id });
    }

    onDueDateSelection = (event) => {
        var dueDate = event.format("YYYY-MM-DD");
        this.setState({ dueDate: dueDate, dueDateSet: true })
    }

    render() {

        const submitButtonClicked = () => {
            
            this.setState({
                buttonText: "Please wait..."
                });
                
        }

        return (
            <form onSubmit={this.props.onSubmit}>
                <h3><center>Create an order for<br />{this.props.itemName}</center></h3>
                <div className="form-group">
                    <label htmlFor="parent_category"><b>Order on behalf of</b></label>
                    <UserSelector onSelection={this.onCategorySelection} />
                    <input type="hidden" id="createdById" value={this.state.selectedID} />
                </div>
                <div className="form-group">
                    <label htmlFor="description"><b>Project</b></label>
                    <ProjectSelector onSelection={this.onProjectSelection} />
                    <input type="hidden" id="projectId" value={this.state.selectedProjectID} />
                </div>
                <div className="form-group">
                    <label htmlFor="dueDate"><b>Due Date</b></label>
                    <Datetime id="dueDate" timeFormat={false} onChange={this.onDueDateSelection} required />
                    <input type="hidden" id="dueDate" value={this.state.dueDate} />
                </div>   
                <div className="form-group">
                    <label htmlFor="quantity"><b>Quantity Required</b></label>
                    <input className="form-control" pattern="\d+\.?\d*" id="quantity" />
                </div>
                <div className="form-group">
                    <label htmlFor="description"><b>Comment</b></label>
                    <input className="form-control" id="comment" />
                </div>
                <input type="hidden" id="itemListingId" value={this.props.itemListingId} />
                <br />
                <div className="form-group">
                    <button disabled={this.state.formSubmitted || this.state.selectedProjectID < 0 || !this.state.dueDateSet} onClick={submitButtonClicked} className="form-control btn btn-primary" type="submit">
                        {this.state.buttonText}
                    </button>
                </div>
            </form>
        );
    }
}