import React, { Component } from 'react';
import { Modal } from './Modal';
import TriggerButton from './TriggerButton';

export class SupplierPopup extends Component {
    state = { isShown: false };
    showModal = () => {
        this.setState({ isShown: true }, () => {
            this.closeButton.focus();
        });
        this.toggleScrollLock();
    };
    closeModal = () => {
        this.setState({ isShown: false });
        this.toggleScrollLock();
    };
    onKeyDown = (event) => {
        if (event.keyCode === 27) {
            this.closeModal();
        }
    };
    onClickOutside = (event) => {
        if (this.modal && this.modal.contains(event.target)) return;
        this.closeModal();
    };

    toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };

    render() {
        
        if (this.props.showLink) {
            var button = <a href onClick={this.showModal}>{this.props.triggerText}</a>
        }
        else {
            var button = <TriggerButton
                showModal={this.showModal}
                buttonRef={(n) => (this.TriggerButton = n)}
                triggerText={this.props.triggerText}
            />
        }        

        return (
            <React.Fragment>
                {button}
                {this.state.isShown ? (
                    <Modal
                        supplierData={this.props.supplierData}
                        modalRef={(n) => (this.modal = n)}
                        buttonRef={(n) => (this.closeButton = n)}
                        closeModal={this.closeModal}
                        onKeyDown={this.onKeyDown}
                        onClickOutside={this.onClickOutside}
                    />
                ) : null}
            </React.Fragment>
        );
    }
}

export default SupplierPopup;