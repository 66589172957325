import React, { Component } from 'react';
import { Spinner, Button } from 'react-bootstrap';
import OrderModal from './OrderSummaryPopup/OrderModal';
import 'bootstrap/dist/css/bootstrap.min.css';

export class OrderCountButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockData: {},
            loading: true,
            isShown: false,
            showButton: true
        };

        setTimeout(() => {
            this.checkOrders();
        }, 1000)
    }

    async checkOrders() {
        const response = await fetch(`api/ordering/get-item-order-counts?itemListingID=${this.props.itemListingId}`);
        const data = await response.json();

        if (data.ordered.count == 0 && data.requested.count == 0){
            this.setState({ showButton: false });
        }
        else {
            this.setState({ stockData: data, loading: false });
        }        
    }

    // Popup Handlers
    showModal = () => {
        this.setState({ isShown: true }, () => {
            this.closeButton.focus();
        });
        this.toggleScrollLock();
    };
    closeModal = () => {
        this.setState({ isShown: false });
        this.toggleScrollLock();
    };
    onKeyDown = (event) => {
        if (event.keyCode === 27) {
            this.closeModal();
        }
    };
    onClickOutside = (event) => {
        //if (this.modal && this.modal.contains(event.target)) return;
        //this.closeModal();
    };

    toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };
    // End Popup Handlers

    render() {

        var buttonVariant = "outline-info";
        var buttonText;

        if (this.state.loading)
        {
            buttonText = <span><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></span>
        }
        else
        {
            buttonText = "🛎 " + this.state.stockData.requested.quantity + " 🚚 " + this.state.stockData.ordered.quantity
            buttonVariant = "outline-secondary"
        }

        return (
            <React.Fragment>
                {this.state.showButton ?
                    <Button variant={buttonVariant} onClick={this.showModal}>
                        {buttonText}
                    </Button>
                 : null}

                {this.state.isShown ? (
                    <OrderModal
                        modalRef={(n) => (this.modal = n)}
                        buttonRef={(n) => (this.closeButton = n)}
                        closeModal={this.closeModal}
                        onKeyDown={this.onKeyDown}
                        onClickOutside={this.onClickOutside}
                        itemListingId={this.props.itemListingId}
                    />
                ) : null}
                
            </React.Fragment>
        );
    }
}