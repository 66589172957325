import React, { Component } from 'react';
import { OrderRequestListing } from './OrderListing/Listings/OrderRequestListing';
import 'bootstrap/dist/css/bootstrap.min.css';


export class IndividualOrderRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {orderRequestData: [], loading: true};
        this.populateOrderRequestList = this.populateOrderRequestList.bind(this);
        this.deleteEntry = this.deleteEntry.bind(this);
    }

    componentDidMount() {
        this.populateOrderRequestList();
    }

    deleteEntry(orderRequestId) {
        var index = this.state.orderRequestData.findIndex(x => x.id === orderRequestId);

        let items = [...this.state.orderRequestData];
        items.splice(index, 1);
        this.setState({ orderRequestData: items });
    }


    renderOrderRequestList(orderRequestData) {
        return (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {
                    orderRequestData.map(orderRequestData => <OrderRequestListing orderRequestData={orderRequestData} updateList={this.populateOrderRequestList} onDelete={this.deleteEntry} onUpdateState={this.updateEntryState} />)
                }
            </div>
        );
    }


    render() {
        return (
            <div style={{ padding: "10px" }} >
                {this.state.loading ?
                    <p>Loading...</p>
                    :
                    this.renderOrderRequestList(this.state.orderRequestData)
                }
            </div>
        );

    }

    async populateOrderRequestList() {
        this.setState({ orderRequestData: [], loading: true });
        const response = await fetch(`api/ordering/list-order-request?id=${this.props.orderRequestId}`);
        const data = await response.json();
        this.setState({ orderRequestData: [ data ], loading: false });
    }
}