import React, { Component } from 'react';
import ItemImage from './ItemImage/ItemImage';

export class ItemListingLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemData: {},
            loading: true
        };
    }

    componentDidMount() {
        this.populateItemDetails();
    }

    async populateItemDetails() {
        const response = await fetch(`api/items/get-item?itemListingId=${this.props.itemListingId}`);
        const data = await response.json();
        this.setState({ itemData: data, loading: false });
    }

    render() {

        if (this.state.loading) {
            return (
                <div>
                    Loading...
                </div>
            );
        }
        else {
            return (
                <div style={{ width: "600px" }}>
                    <div className="item-list-blank">
                        <ItemImage imageId={this.state.itemData.imageId} />
                        <div style={{ width: "100%" }}>
                            <div>
                                <p className="item-large-heading">{this.state.itemData.name}</p>
                            </div>
                            <div style={{ display: "inline-block", width: "70%", minHeight: "92px" }}>
                                <hr style={{ margin: "2px 0 2px 0", width: "90%", color: "#999999" }} />
                                {"category" in this.state.itemData ? <p className="item-large-description"><b>Category:</b> {this.state.itemData.category.name} <b>Id:</b> {this.state.itemData.id}</p> : null}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}


