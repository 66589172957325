import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { OrderingOptions } from '../OrderingOptions';
import { StockCheckButton } from '../../StockOrderButton/StockCheckButton';
import { OrderCountButton } from '../../StockOrderButton/OrderCountButton';
import OrderItemPopup from '../OrderItemPopup/OrderItemPopup';
import ItemImage from '../../ItemListing/ItemImage/ItemImage';
import { format } from "date-fns";

export class OrderRequestListing extends Component {
    constructor(props) {
        super(props);
        this.state = {showOrderPopup: false };
        this.onOrderButtonPress = this.onOrderButtonPress.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    };


    static defaultProps = {
        onUpdateState: (id, newState) => { },
        onDelete: (id) => { }
    }


    onOrderButtonPress(orderInfoId) {
        this.setState({ showOrderPopup: true, orderInfoIDSelected: orderInfoId });
    }

    onCloseModal() {
        this.setState({ showOrderPopup: false });
    }

    onSubmit() {
        this.props.updateList();
    }

    onRequestDelete()
    {
        const response = fetch(`api/ordering/cancel-request?orderRequestId=${this.props.orderRequestData.id}`)
            .then(this.props.onDelete(this.props.orderRequestData.id));
    }

    deleteConfirmationButton = () => {
        confirmAlert({
            title: 'Cancel request?',
            message: 'Are you sure you want to delete the request for: \"' + this.props.orderRequestData.itemListing.name + '\"?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.onRequestDelete(),
                    className: 'btn btn-danger'
                },
                {
                    label: 'No',
                    className: 'btn btn-primary'
                }
            ]
        });
    };

    updateOrderRequestState(newState) {
        const response = fetch(`api/ordering/update-procurement-state?orderRequestId=${this.props.orderRequestData.id}&state=${newState}`)
            .then(this.props.onUpdateState(this.props.orderRequestData.id, newState));
    }

    render() {

        var backgroundColour = "#fafafa";
        if (this.props.orderRequestData.urgency === 'Urgent') {
            backgroundColour = "#ffe5e5"
        }

        var createdDate = new Date(this.props.orderRequestData.created);

        return (
            <div style={{ width: "610px", border: "4px solid lightgrey", margin: "10px", padding: "10px", background: backgroundColour }}>

                {this.state.showOrderPopup ?
                    <OrderItemPopup
                        onCloseModal={this.onCloseModal}
                        onSubmit={this.onSubmit}
                        itemName={this.props.orderRequestData.itemListing.name}
                        itemListingId={this.props.orderRequestData.itemListingId}
                        measurementUnit={this.props.orderRequestData.itemListing.measurementUnit}
                        defaultCreationQuantity={this.props.orderRequestData.itemListing.defaultCreationQuantity}
                        orderRequestId={this.props.orderRequestData.id}
                        orderInfoId={this.state.orderInfoIDSelected}
                    />
                    :
                    null
                }

                <div style={{ display: "flex" }}>
                    <ItemImage imageId={this.props.orderRequestData.itemListing.imageId} />
                    <div style={{ width: "100%", marginLeft: "10px"}}>
                        <p className="item-large-heading">{this.props.orderRequestData.itemListing.name}
                            <span style={{ float: "right", textAlign: "right" }}>
                                <ButtonGroup vertical size="sm">
                                    <Button onClick={this.deleteConfirmationButton} variant="danger">Cancel Request</Button>
                                    <StockCheckButton itemListingId={this.props.orderRequestData.itemListingId} unit={this.props.orderRequestData.itemListing.measurementUnit} />
                                    <OrderCountButton itemListingId={this.props.orderRequestData.itemListingId} />
                                    <DropdownButton as={ButtonGroup} id="bg-vertical-dropdown-1" variant="outline-dark" size="sm" title="Change State">
                                        <Dropdown.Item onClick={() => this.updateOrderRequestState("New")}> New</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.updateOrderRequestState("Quotation")}>In Quotation</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.updateOrderRequestState("Awaiting Approval")}>Awaiting Approval</Dropdown.Item>
                                    </DropdownButton>
                                </ButtonGroup>                                
                            </span></p>
                        <p className="item-large-description">Quantity remaining: <b>{this.props.orderRequestData.quantityRemaining}</b></p>
                        <p className="item-large-description">Reason:<b> {this.props.orderRequestData.comment}</b></p>
                        <p className="item-large-description">Requested by:<b> {this.props.orderRequestData.createdBy.name}</b></p>
                        <p className="item-large-description">Urgency:<b> {this.props.orderRequestData.urgency}</b> | State: <b>{this.props.orderRequestData.procurementState}</b></p>
                        <p className="item-large-description">Created:<b> {format(createdDate, "dd MMM yyyy HH:mm")}</b> | ID: <b>{this.props.orderRequestData.id}</b></p>
                        {this.props.orderRequestData.projectPlaceCard ? <p className="item-large-description">Project Place Card:<a href={`https://service.projectplace.com/#direct/card/${this.props.orderRequestData.projectPlaceCard}`}>{this.props.orderRequestData.projectPlaceCard}</a></p> : null }
                    </div>
                </div>
                <center><hr style={{ width: "50%" }} /></center>
                <OrderingOptions itemListingId={this.props.orderRequestData.itemListingId} onOrderButtonPress={this.onOrderButtonPress} />
            </div>
        );
    }
}
