import React, { Component } from 'react';
import { UserSelector } from './UserSelector/UserSelector';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

export class AlternateSignin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUserID : 0
        }

        this.onUserSelection = this.onUserSelection.bind(this);
    }

    onUserSelection = (id) => {
        this.setState({ selectedUserID: id });
    }

    async onSubmit() {
        const response = await fetch(`api/users/tablet-signin?id=${this.state.selectedUserID}`);
        const data = await response.json();
        window.location.reload(true);
    }

    render() {
        var userSelected = this.state.selectedUserID > 0;
        var buttonVariant = userSelected ? buttonVariant = "success": buttonVariant = "secondary";
        var buttonDisabled = !userSelected;

        return (
            <div>
                <p><b>Select who you are to continue</b></p>
                <UserSelector onSelection={this.onUserSelection} />
                <Button onClick={() => this.onSubmit()} disabled={buttonDisabled} variant={buttonVariant}> Login </Button>
            </div>
        );
    }
}
